.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #0059AC;
  line-height: 1.55555556;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #004c93;
}
.button:active {
  background-color: #003160;
}
#home {
  height: 40px;
}
.container--head {
  margin-top: 120px;
  margin-bottom: 160px;
}
.section--footer {
  margin-top: calc(var(--spaceTotal) * 3);
}
.footarea {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 65px;
}
.footpart {
  width: 31.25%;
}
.footpart--right {
  margin-top: 0;
}
.footpart--desktop {
  display: block;
}
.footpart--mobile {
  display: none;
}
.contact {
  margin-top: 30px;
}
.cbdModule--copyright {
  margin-top: 29px;
}
.services {
  margin-top: 29px;
}
.area--one .unitOne1-2--positioningSpaceBetween .unit__content,
.area--one .unitOne1-2--positioningSpaceBetween .unit__body {
  height: 100%;
}
.area--one .unitOne1-2--positioningSpaceBetween .unit__body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}
.area--one .unitOne1-2--positioningEnd .unit__content {
  display: flex;
  align-items: flex-end;
}
.area--one .unitOne1-2--positioningEnd .unit__content .unit__head,
.area--one .unitOne1-2--positioningEnd .unit__content .unit__foot {
  display: none;
}
.area--one .unitThree {
  width: 98.7654321%;
}
.area--one .unitThree .unit__body {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.area--one .unitThree .part.pict {
  width: 100% !important;
  max-width: 790px;
}
.area--one .unitThree .part.pict:nth-child(4n+2),
.area--one .unitThree .part.pict:nth-child(4n+3) {
  aspect-ratio: 0.66666667;
}
@supports not (aspect-ratio: 790 /  1185) {
  .area--one .unitThree .part.pict:nth-child(4n+2):before,
  .area--one .unitThree .part.pict:nth-child(4n+3):before {
    float: left;
    padding-top: 150%;
    content: '';
  }
  .area--one .unitThree .part.pict:nth-child(4n+2):after,
  .area--one .unitThree .part.pict:nth-child(4n+3):after {
    display: block;
    content: '';
    clear: both;
  }
}
.area--one .unitThree .part.pict:nth-child(4n+1),
.area--one .unitThree .part.pict:nth-child(4n+4) {
  aspect-ratio: 1.49056604;
}
@supports not (aspect-ratio: 790 /  530) {
  .area--one .unitThree .part.pict:nth-child(4n+1):before,
  .area--one .unitThree .part.pict:nth-child(4n+4):before {
    float: left;
    padding-top: 67.08860759%;
    content: '';
  }
  .area--one .unitThree .part.pict:nth-child(4n+1):after,
  .area--one .unitThree .part.pict:nth-child(4n+4):after {
    display: block;
    content: '';
    clear: both;
  }
}
.area--one .unitThree .part.pict:nth-child(4n+1) {
  grid-row: span 1;
}
.area--one .unitThree .part.pict:nth-child(4n+2) {
  grid-row: span 2;
}
.area--one .unitThree .part.pict:nth-child(4n+3) {
  grid-row: span 2;
}
.area--one .unitThree .part.pict:nth-child(4n+4) {
  grid-row: span 1;
}
.area--one .unitFour--1-1-indent {
  width: 49.38271605%;
  margin-left: 25.30864198%;
  margin-right: 25.30864198%;
}
.area--one .unitFour--1-4 {
  width: 20.98765432%;
}
.area--one .unitFour .unit__background {
  width: 140px;
}
.area--one .unitFive .unit__body {
  padding-top: 81px;
  background-size: 72px 64px;
}
.part--widget .cb-googlemapscontainer {
  height: 600px !important;
}
.partFileSize--social {
  margin-top: calc(var(--spacePart) * 2);
}
/*# sourceMappingURL=./screen-large.css.map */